import React from "react"
import { Link } from "gatsby"
import DesktopNav from "./desktopnav.js"
import MobileNav from "./mobilenav.js"

import "./header.css"
import accesSOSLogo from "../images/accesSOS-logo.svg"
import accesSOS_logo_transparent_cropped from "../images/accesSOS_logo_transparent_cropped.png"

// function linkClick(e) {
//   console.log(e.target.value)
// }

class Header extends React.Component {
  constructor() {
    super()
    this.state = {
      open: false,
      width: 0
    }
  }

  // CHECK CONSOLE - some warning messages
  openMenu = () => {
    this.setState({
      open: true,
    })
  }

  closeMenu = () => {
    this.setState({
      open: false,
    })
  }
  // useEffect(() => {
  //   //Runs on the first render
  //   //And any time any dependency value changes
  //   if(window.location.href.endsWith("/")) {
  //     document.getElementById("home").classList.toggle("selected");
  //   }
  //   if(window.location.href.endsWith("how-it-works")) {
  //     document.getElementById("how-it-works").classList.toggle("selected");
  //   }
  //   if(window.location.href.endsWith("story")) {
  //     document.getElementById("about").classList.toggle("selected");
  //   }
  // }, [window.location.href]);

  isActive( {isCurrent} ) {
    console.log(isCurrent)
    return isCurrent ? {className: "selected"} : null
  }

  render() {
    return (
      <header className="header-container">
        <div id="desktop-navbar">
          <div>
            <DesktopNav/>
            <a 
    href="/" 
    onClick={(e) => {
      // Scroll to top of the page
      window.scrollTo({ top: 0, behavior: 'instant' });
      // Optionally, allow the default link behavior to occur
    }}
  ><img alt="access S O S Logo" src={accesSOSLogo}/></a>
          </div>
          <ul>
            <li>
              <Link to="/" getProps={this.isActive}> Home </Link> 
            </li>
            <li>
              <Link to="/#our-story" getProps={this.isActive}> About </Link> 
            </li>
            <li>
              <Link to="/how-it-works" getProps={this.isActive}> How it works </Link> 
            </li>
            <li>
              <Link to="/press" getProps={this.isActive}> Press </Link> 
            </li>
            <li>
              <Link to="/faq" getProps={this.isActive}> FAQs </Link> 
            </li>
            <li>
              <a className="donate-button" href="https://donorbox.org/911textapp-nationwide" rel="noreferrer" target="_blank"> Donate </a> 
            </li>
          </ul>
        </div>
        <div id="mobile-navbar">
        <a href="/"   onClick={(e) => {
      // Scroll to top of the page
      window.scrollTo({ top: 0, behavior: 'instant' });
      // Optionally, allow the default link behavior to occur
    }}>
  <img alt="access S O S Logo" src={accesSOSLogo} />
</a>
          <div className="right-side">
            <a className="donate-button" href="https://donorbox.org/911textapp-nationwide" rel="noreferrer" target="_blank"> Donate </a> 
            <MobileNav/>
          </div> 
          
        </div>
      </header>
        // <header className="header-container">
        //   <div className="desktop-header">
        //     <div>
        //       <div openmenu openMenu={this.openMenu}>
        //         <DesktopNav />
        //       </div>
        //       <Link to="/#home">
        //         <img id='main-logo' src={accesSOSLogo}/>
        //       </Link>
        //     </div>
        //     <div id="nav-links">
        //       <Link
        //         to="/#our-story"
        //         activeStyle={{ color: "#939292" }}
        //         className="desktop-link"
        //       >
        //         About
        //       </Link>
        //       <Link
        //         to="/how-it-works"
        //         activeStyle={{ color: "#939292" }}
        //         className="desktop-link"
        //       >
        //         Learn
        //       </Link>
        //       <Link
        //         to="/press"
        //         activeStyle={{ color: "#939292" }}
        //         className="desktop-link"
        //       >
        //         Press
        //       </Link>
              

        //       <Link
        //         to="/faq"
        //         activeStyle={{ color: "#939292" }}
        //         className="desktop-link"
        //       >
        //         Support
        //       </Link>
        //       <a
        //         href="https://donorbox.org/911textapp-nationwide"
        //         target="_blank"
        //         rel="noreferrer"
        //       >
        //         <button className="desktop-donate">Donate</button>
        //       </a>
        //     </div>
            
        //   </div>
        //   <div className="mobile-header">
        //     <div>
        //       <Link to="/#home">
        //         <img id='main-logo' src={accesSOSLogo}/>
        //       </Link>
        //     </div>
            
        //     <div closeMenu={this.closeMenu}>
        //       <MobileNav />
        //     </div>
        //   </div>
        // </header>
      // </div>
    )
  }
}

export default Header
